import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Trademarks, SEO } from '@/components'

export const pageQuery = graphql`
  query TrademarksIndex {
    prismicMeta {
      data {
        social_image {
          url
        }
      }
    }
  }
`

const TrademarksPage = ({ data, pageContext }) => {
  const { social_image } = data.prismicMeta.data

  return (
    <Layout locale={pageContext.lang}>
      <SEO title="Trademarks" image={social_image.url} />
      <Trademarks />
    </Layout>
  )
}

export default TrademarksPage
